import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function PteSnackBar({
  snackOpen,
  message,
  handleClose,
  messageStatus = "error",
}) {
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackOpen}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          severity={messageStatus}
          onClose={handleClose}
          variant="filled"
          sx={{ width: "100%" }}
          autoHideDuration={5000}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
