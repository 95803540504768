import { useQuery } from "@tanstack/react-query";
import { dataFetcher } from "../utils";
import { baseURL } from "../constants";

export const useGetUserList = (details) => {
  const getUserList = async () => {
    const url = `${baseURL}v1/user/listing`;
    const data = await dataFetcher(url, "get", details);
    return data;
  };
  return useQuery({
    queryKey: ["getUserList"],
    queryFn: getUserList,
    enabled: false,
    retry: false,
  });
};

export const useGetTotalCount = () => {
  const getTotalCount = async () => {
    const url = `${baseURL}v1/totalCount`;
    const data = await dataFetcher(url, "get");
    return data;
  };
  return useQuery({
    queryKey: ["getTotalCount"],
    queryFn: getTotalCount,
    enabled: true,
    retry: false,
    refetchInterval: 5000,
  });
};

export const useUserStatus = (details) => {
  const userStatus = async () => {
    if (details.userId > 0) {
      const url = `${baseURL}v1/user`;
      const data = await dataFetcher(url, "post", details);
      return data;
    }
  };
  return useQuery({
    queryKey: ["userStatus"],
    queryFn: userStatus,
    enabled: false,
    retry: false,
  });
};
