import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ResponsiveAppBar from "../../components/navbar";
import {
  useGetTotalCount,
  useGetUserList,
  useUserStatus,
} from "../../services/adminService";
import { CircularProgress, Switch, Button } from "@mui/material";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import styles from "./admin.module.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { baseURL } from "../../constants";

export default function AdminDashboard() {
  const onIdle = () => {
    localStorage.clear("userId");
    localStorage.clear("isAdmin");
    window.location.reload();
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 20 * 60 * 1000,
  });
  const [userData, setUserData] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const [userStatusId, setUserStatusId] = useState(0);
  const [userStatusValue, setUserStatusValue] = useState(false);
  const [countData, setCountData] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [tags, setTags] = useState([]);
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [headerTexts, setHeaderTexts] = useState("");

  const tagsPerRow = 8; // Number of header tags to display per row

  const fetchHedersData = async () => {
    try {
      const response = await fetch(`${baseURL}v1/getHeaders`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setHeaderTexts(data.data);
    } catch (error) {
      console.error("There was a problem with the fetch headers:", error);
    }
  };

  const getRows = () => {
    const rows = [];
    for (let i = 0; i < headerTexts.length; i += tagsPerRow) {
      rows.push(headerTexts.slice(i, i + tagsPerRow));
    }
    return rows;
  };

  const openModal = () => {
    fetchHedersData();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    if (tags.length === 0) {
      setError("At least one header text is required.");
      setSuccessMessage("");
      setOpenSnackbar(true);
      return;
    }

    try {
      const formData = new FormData();
      const header_texts = tags.join(",");

      // Append the comma-separated string to the form data
      formData.append("header_value", header_texts);

      const response = await fetch(`${baseURL}v1/addHeader`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      console.log("Tags submitted successfully");
      setOpenSnackbar(true);
      setSuccessMessage("Headers added successfully.");
      console.log("Success message");
      closeModal();
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleAddTag = () => {
    if (headerText.trim() && !tags.includes(headerText.trim())) {
      setTags([...tags, headerText.trim()]);
      setHeaderText(""); // Clear the input field
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const { refetch: userStatus, data: userStatusData } = useUserStatus({
    userId: userStatusId,
    isActive: !userStatusValue,
  });

  const handleChange = (userId, status) => {
    if (!localStorage.getItem("userId")) {
      navigate("/login");
    } else {
      setUserStatusId(userId);
      setUserStatusValue(status);
    }
  };

  const columns = [
    { field: "userId", headerName: "User ID", width: 200 },
    { field: "firstName", headerName: "First name", width: 200 },
    { field: "lastName", headerName: "Last name", width: 200 },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "convertedFileCount",
      headerName: "Conversion Count",
      width: 200,
    },
    {
      field: "isActive",
      headerName: "User Status",
      width: 200,
      renderCell: (params) => {
        const userId = params.row.userId;
        const status = params.row.isActive;
        return (
          <Switch
            checked={params.row.isActive}
            onChange={() => handleChange(userId, status)}
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
  ];

  const {
    refetch: getUserListService,
    data: userListData,
    isLoading,
    isFetching,
  } = useGetUserList({
    pageNo: paginationModel.page,
    limit: paginationModel.pageSize,
    search_text: searchText,
  });

  const {
    refetch: getTotalCount,
    data: totalCountData,
    isLoading: countDataLoading,
  } = useGetTotalCount();

  useEffect(() => {
    if (
      totalCountData &&
      totalCountData.statusCode &&
      totalCountData.statusCode === "200"
    ) {
      setCountData(totalCountData.data);
    }
  }, [totalCountData]);

  useEffect(() => {
    getUserListService();
  }, [paginationModel]);

  useEffect(() => {
    if (userListData && userListData.data && userListData.data.userDetails) {
      setUserData(userListData.data.userDetails);
      setRowCount(userListData.data.totalRows);
    }
  }, [userListData && userListData.data && userListData.data.userDetails]);

  useEffect(() => {
    if (userStatusId > 0) {
      (async () => {
        const data = await userStatus();
        if (data && data.data && data.data.statusCode == "200") {
          getUserListService();
          setUserStatusId(0);
          setUserStatusValue(false);
        }
      })();
    }
  }, [userStatusId]);

  useEffect(() => {
    getUserListService();
  }, [searchText]);

  return (
    <div>
      <ResponsiveAppBar pages={["Admin Screen"]} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 20,
          alignItems: "center",
        }}
      >
        <h1></h1>
        <h3>
          Total Conversion Count:{" "}
          {countDataLoading ? <CircularProgress /> : countData}
        </h3>
        <div style={{ marginRight: "4%" }}>
          {" "}
          <Button variant="contained" onClick={openModal}>
            add headers
          </Button>
        </div>
      </div>

      <div className={styles.mainContainer}>
        <div style={{ width: "90%", height: "auto" }}>
          <DataGrid
            getRowId={(row) => row.userId}
            rows={userData}
            columns={columns}
            paginationModel={paginationModel}
            paginationMode="server"
            rowCount={rowCount}
            loading={isLoading || isFetching}
            pageSize={10}
            autoHeight={true}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10]}
            slots={{ toolbar: GridToolbar }}
            filterMode="server"
            disableColumnFilter
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 400 },
              },
            }}
            onFilterModelChange={(newFilterModel) => {
              const { quickFilterValues: [item] = [] } = newFilterModel;
              if (item) {
                setSearchText(item);
              } else {
                setSearchText("");
              }
            }}
          />
        </div>
      </div>

      {/* Modal for adding headers */}
      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            width: "100%",
            height: "90%",
            maxHeight: "90vh",
          },
        }}
      >
        <DialogTitle>Add New Headers</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 10,
              alignItems: "center",
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              label="Header Text"
              type="text"
              fullWidth
              variant="outlined"
              value={headerText}
              onChange={(e) => setHeaderText(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent form submission on Enter key
                  handleAddTag();
                }
              }}
            />
            <Button
              onClick={handleAddTag}
              color="primary"
              variant="contained"
              size="large"
              style={{ marginLeft: "3%" }}
            >
              Add
            </Button>
          </div>
          <Stack spacing={1}>
            {/* Display tags */}
            <Stack direction="row" spacing={1} mb={2}>
              {tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={() => handleRemoveTag(tag)}
                />
              ))}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeModal}
            color="primary"
            variant="contained"
            size="small"
          >
            Cancel
          </Button>

          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            size="small"
          >
            Submit
          </Button>
        </DialogActions>
        {/* Divider and header Texts */}
        <Divider variant="middle" sx={{ marginTop: 2, marginBottom: 2 }} />
        <DialogTitle>Existing Headers</DialogTitle>
        <DialogContent style={{ maxHeight: "200" }}>
          <div style={{ padding: 10 }}>
            <Stack spacing={1}>
              {getRows().map((row, rowIndex) => (
                <Stack key={rowIndex} direction="row" spacing={1} mb={2}>
                  {row.map((text) => (
                    <Chip key={text} label={text} />
                  ))}
                </Stack>
              ))}
            </Stack>
          </div>
        </DialogContent>
      </Dialog>
      {/* Snackbar for error message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {successMessage ? (
          <Alert onClose={handleSnackbarClose} severity="success">
            {successMessage}
          </Alert>
        ) : (
          <Alert onClose={handleSnackbarClose} severity="error">
            {error}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}
