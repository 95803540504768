// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_dropContainerInfo__B3nWb {
  margin: 10px 0 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #e34c2d;
}

.dashboard_singlePdfContainer__aJz5z {
  display: flex;
  align-items: center;
}

.dashboard_singlePdfFile__\\+kxwN {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80px;
  width: 80px;
  padding: 15px;
  background-color: #ebeff0;
  border-radius: 4px;
  margin-bottom: 10px;
}

.dashboard_btnStyle__Eyj0r {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
}

.dashboard_mainContainer__WG5bz {
  padding: 10px 0 50px 100px;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/containers/dashboard/dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".dropContainerInfo {\n  margin: 10px 0 0 50px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  color: #e34c2d;\n}\n\n.singlePdfContainer {\n  display: flex;\n  align-items: center;\n}\n\n.singlePdfFile {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 80px;\n  width: 80px;\n  padding: 15px;\n  background-color: #ebeff0;\n  border-radius: 4px;\n  margin-bottom: 10px;\n}\n\n.btnStyle {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-right: 50px;\n}\n\n.mainContainer {\n  padding: 10px 0 50px 100px;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropContainerInfo": `dashboard_dropContainerInfo__B3nWb`,
	"singlePdfContainer": `dashboard_singlePdfContainer__aJz5z`,
	"singlePdfFile": `dashboard_singlePdfFile__+kxwN`,
	"btnStyle": `dashboard_btnStyle__Eyj0r`,
	"mainContainer": `dashboard_mainContainer__WG5bz`
};
export default ___CSS_LOADER_EXPORT___;
