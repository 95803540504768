// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin_mainContainer__S1RT1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/containers/admin/admin.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB","sourcesContent":[".mainContainer {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `admin_mainContainer__S1RT1`
};
export default ___CSS_LOADER_EXPORT___;
