import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { useForgotPassword } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import PteSnackBar from "../../components/pteSnack";
import { isValidEmail } from "../../utils";
import AppLogo from "../../components/appLogo";
import styles from "./auth.module.css";

const defaultTheme = createTheme();

export default function ForgotPassword() {
  const [details, setDetails] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();
  const {
    refetch: forgotPasswordService,
    data: forgotPasswordData,
    isLoading,
    isFetching,
  } = useForgotPassword(details);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const enteredEmail = data.get("email");
    if (isValidEmail(enteredEmail)) {
      setDetails({
        email: data.get("email"),
      });
    } else {
      setEmailError(true);
    }
  };

  const handleClose = () => {
    setSnackOpen(false);
  };

  useEffect(() => {
    if (details) {
      forgotPasswordService();
    }
  }, [details]);

  useEffect(() => {
    if (forgotPasswordData && !isFetching) {
      if (
        forgotPasswordData.statusCode === "200" &&
        forgotPasswordData.status === "success"
      ) {
        if (details && details.email) {
          localStorage.setItem("email", details.email);
          navigate("/changePassword");
        }
      } else {
        setSnackMessage(forgotPasswordData.message);
        setSnackOpen(true);
      }
    }
  }, [forgotPasswordData, isFetching]);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      navigate("/");
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className={styles.boxContainer}>
          <AppLogo />
          <Typography component="h1" variant="h5">
            Enter your email to send an OTP
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={() => setEmailError(false)}
              error={emailError}
              helperText={emailError ? "Please enter a valid email" : ""}
              autoFocus
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading || isFetching}
              sx={{ mt: 3, mb: 2 }}
            >
              Send verfication code
            </LoadingButton>
          </Box>
        </Box>
        <PteSnackBar
          message={snackMessage}
          snackOpen={snackOpen}
          handleClose={handleClose}
        />
      </Container>
    </ThemeProvider>
  );
}
