import axios from "axios";

export const dataFetcher = async (url, method, body) => {
  if (method === "get") {
    const response = await axios
      .get(url, {
        params: body,
      })
      .then((res) => res)
      .catch((err) => err);
    let finalResponse = "";
    if (response.data) {
      finalResponse = response.data;
    } else {
      finalResponse = response.response.data;
    }
    return finalResponse;
  } else {
    const response = await axios
      .post(url, body)
      .then((res) => res)
      .catch((err) => err);
    let finalResponse = "";
    console.log("Sfdfdf", response);
    if (response.data) {
      finalResponse = response.data;
    } else {
      finalResponse = response.response.data;
    }
    return finalResponse;
  }
};

export const isValidEmail = (email) => {
  return email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/);
};

export const isValidName = (name) => {
  return name.match(/^[a-zA-Z ]*$/);
};

export const isValidOTP = (otp) => {
  return otp.match(/^\d{6}$/);
};

export const isValidPassword = (password) => {
  return password.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/);
};
