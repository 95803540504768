import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Login from "./containers/auth/login";
import Register from "./containers/auth/register";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import Dashboard from "./containers/dashboard/dashboard";
import ForgotPassword from "./containers/auth/forgotPassword";
import ChangePassword from "./containers/auth/changePassword";
import AdminDashboard from "./containers/admin/adminDashboard";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

const isAuthenticated = false;

const ProtectedRoutes = () => {
  // TODO: Use authentication token
  const localStorageToken = localStorage.getItem("userId");

  return localStorageToken ? getDashBoard() : <Navigate to="/login" replace />;
};

const getDashBoard = () => {
  const isAdmin = localStorage.getItem("isAdmin");
  return isAdmin == "true" ? <AdminDashboard /> : <Dashboard />;
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/changePassword",
    element: <ChangePassword />,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/",
        element: getDashBoard(),
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router}>
        {isAuthenticated ? getDashBoard() : <Navigate to="/login" />}
      </RouterProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
