import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { useLogin } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import PteSnackBar from "../../components/pteSnack";
import { isValidEmail } from "../../utils";
import AppLogo from "../../components/appLogo";
import styles from "./auth.module.css";

const defaultTheme = createTheme();

export default function Login() {
  const [details, setDetails] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();
  const {
    refetch: loginService,
    data: loginData,
    isLoading,
    isFetching,
  } = useLogin(details);

  const handleClose = () => {
    setSnackOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const enteredEmail = data.get("email").trim();
    if (isValidEmail(enteredEmail)) {
      setDetails({
        email: enteredEmail,
        password: data.get("password"),
      });
    } else {
      setEmailError(true);
    }
  };

  useEffect(() => {
    if (details) {
      loginService();
    }
  }, [details]);

  useEffect(() => {
    if (loginData && !isFetching) {
      if (loginData.statusCode === "200" && loginData.status === "success") {
        localStorage.setItem("userId", loginData.data.id);
        localStorage.setItem("isAdmin", loginData.data.isAdmin);
        navigate("/");
      } else {
        setSnackMessage(loginData.message);
        setSnackOpen(true);
      }
    }
  }, [loginData, isFetching]);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      navigate("/");
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className={styles.boxContainer}>
          <AppLogo />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={() => setEmailError(false)}
              error={emailError}
              helperText={emailError ? "Please enter a valid email" : ""}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading || isFetching}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                <Link href="/forgotPassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <PteSnackBar
          message={snackMessage}
          snackOpen={snackOpen}
          handleClose={handleClose}
        />
      </Container>
    </ThemeProvider>
  );
}
