// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth_boxContainer__0CQLT {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth_otpBox__0hI5\\+ {
  display: flex;
  width: 100%;
  margin: 15px 0 0 15px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/containers/auth/auth.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":[".boxContainer {\n  margin-top: 50px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.otpBox {\n  display: flex;\n  width: 100%;\n  margin: 15px 0 0 15px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxContainer": `auth_boxContainer__0CQLT`,
	"otpBox": `auth_otpBox__0hI5+`
};
export default ___CSS_LOADER_EXPORT___;
