import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  useRegister,
  useSendOtp,
  useVerifyOtp,
} from "../../services/authService";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import PteSnackBar from "../../components/pteSnack";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { isValidEmail, isValidName } from "../../utils";
import AppLogo from "../../components/appLogo";
import styles from "./auth.module.css";
import { isValidOTP, isValidPassword } from "../../utils";

const defaultTheme = createTheme();

export default function Register() {
  const [details, setDetails] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const [isOtpRequested, setIsOtpRequested] = useState(false);
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  const [startTimer, setStartTimer] = useState(false);
  const [otpDetails, setOtpDetails] = useState(null);
  const [messageStatus, setMessageStatus] = useState("error");
  const [emailError, setEmailError] = useState(false);
  const [fnameError, setFNameError] = useState(false);
  const [lNameError, setLNameError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const navigate = useNavigate();
  const {
    refetch: registerService,
    data: registerData,
    isLoading,
    isFetching,
  } = useRegister(details);

  const {
    refetch: verifyOtpService,
    data: verifyOtpData,
    isLoading: isOtpLoading,
    isFetching: isOtpFetching,
  } = useVerifyOtp(otpDetails);

  const {
    refetch: resendOtpService,
    data: resendOtpData,
    isLoading: isResendLoading,
    isFetching: isResendFetching,
  } = useSendOtp({
    email: details && details.email,
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (showOtpBox) {
      const enteredOtp = data.get("otp").trim();
      const validOtp = isValidOTP(enteredOtp);
      if (validOtp) {
        const otpDetail = { email: details.email, otp: enteredOtp };
        setOtpDetails(otpDetail);
      } else {
        setOtpError(true);
      }
    } else {
      const enteredEmail = data.get("email").trim();
      const enteredfName = data.get("firstName").trim();
      const enteredlName = data.get("lastName").trim();
      const enteredPassword = data.get("password").trim();
      const validEmail = isValidEmail(enteredEmail);
      const validFName = isValidName(enteredfName);
      const validLName = isValidName(enteredlName);
      const validPassword = isValidPassword(enteredPassword);

      if (validEmail && validFName && validLName && validPassword) {
        setDetails({
          firstName: enteredfName,
          lastName: enteredlName,
          email: enteredEmail,
          password: enteredPassword,
        });
      } else {
        if (!validEmail) setEmailError(true);
        if (!validFName) setFNameError(true);
        if (!validLName) setLNameError(true);
        if (!validPassword) setPasswordError(true);
      }
    }
  };

  const handleClose = () => {
    setSnackOpen(false);
  };

  useEffect(() => {
    if (details) {
      registerService();
    }
  }, [details]);

  useEffect(() => {
    verifyOtpService();
  }, [otpDetails]);

  useEffect(() => {
    if (registerData && !isFetching) {
      if (
        registerData.statusCode === "200" &&
        registerData.status === "success"
      ) {
        setMessageStatus("success");
        setShowOtpBox(true);
        setIsOtpRequested(true);
        setSnackMessage(registerData.message);
        setSnackOpen(true);
      } else {
        setMessageStatus("error");
        setSnackMessage(registerData.message);
        setSnackOpen(true);
      }
    }
  }, [registerData, isFetching]);

  useEffect(() => {
    if (verifyOtpData && !isOtpFetching) {
      if (
        verifyOtpData.statusCode === "200" &&
        verifyOtpData.status === "success"
      ) {
        setSnackMessage(verifyOtpData.message);
        setSnackOpen(true);
        navigate("/login");
      } else {
        setMessageStatus("error");
        setSnackMessage(verifyOtpData.message);
        setSnackOpen(true);
      }
    }
  }, [verifyOtpData, isOtpFetching]);

  useEffect(() => {
    if (resendOtpData && !isResendFetching) {
      if (
        resendOtpData.statusCode === "200" &&
        resendOtpData.status === "success"
      ) {
        setMessageStatus("success");
      } else {
        setMessageStatus("error");
      }
      setSnackMessage(resendOtpData.message);
      setSnackOpen(true);
    }
  }, [resendOtpData, isResendFetching]);

  useEffect(() => {
    if (verifyOtpData && !isOtpFetching) {
      if (
        verifyOtpData.statusCode === "200" &&
        verifyOtpData.status === "success"
      ) {
        setSnackMessage(verifyOtpData.message);
        setSnackOpen(true);
        navigate("/login");
      } else {
        setSnackMessage(verifyOtpData.message);
        setSnackOpen(true);
      }
    }
  }, [verifyOtpData, isOtpFetching]);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      navigate("/");
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className={styles.boxContainer}>
          <AppLogo />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  onChange={() => setFNameError(false)}
                  error={fnameError}
                  helperText={fnameError ? "Please enter only alphabets" : ""}
                  autoFocus
                  disabled={showOtpBox}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  onChange={() => setLNameError(false)}
                  error={lNameError}
                  helperText={lNameError ? "Please enter only alphabets" : ""}
                  autoComplete="family-name"
                  disabled={showOtpBox}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  emailError
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={() => setEmailError(false)}
                  error={emailError}
                  helperText={emailError ? "Please enter a valid email" : ""}
                  disabled={showOtpBox}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={() => setPasswordError(false)}
                  error={passwordError}
                  helperText={
                    passwordError
                      ? "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character (e.g., !@#$%^&*)."
                      : ""
                  }
                  disabled={showOtpBox}
                />
              </Grid>
              <div className={styles.otpBox}>
                {showOtpBox && (
                  <Grid item style={{ maxWidth: "60%" }} xs={12}>
                    <TextField
                      required
                      name="otp"
                      label="Enter OTP"
                      id="otp"
                      onChange={() => setOtpError(false)}
                      error={otpError}
                      helperText={otpError ? "Please enter a valid OTP" : ""}
                    />
                  </Grid>
                )}
                {showOtpBox ? (
                  showTimer ? (
                    <CountdownCircleTimer
                      isPlaying
                      duration={120}
                      colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                      isSmoothColorTransition
                      colorsTime={[30, 20, 10, 5]}
                      size={50}
                      strokeWidth={6}
                      onComplete={(totalElapsedTime) => {
                        setShowTimer(false);
                        return { shouldRepeat: startTimer, delay: 1.5 };
                      }}
                    >
                      {({ remainingTime }) => remainingTime}
                    </CountdownCircleTimer>
                  ) : (
                    <LoadingButton
                      onClick={() => {
                        resendOtpService();
                        setShowTimer(true);
                        setStartTimer(true);
                      }}
                    >
                      Resend OTP
                    </LoadingButton>
                  )
                ) : null}
              </div>

              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I agree to the terms and condtions."
                />
              </Grid> */}
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={isLoading || isFetching}
            >
              {isOtpRequested ? "Sign Up" : "Get OTP"}
            </LoadingButton>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <PteSnackBar
          messageStatus={messageStatus}
          message={snackMessage}
          snackOpen={snackOpen}
          handleClose={handleClose}
        />
      </Container>
    </ThemeProvider>
  );
}
