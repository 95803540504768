import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import {
  useChangePassword,
  useForgotPassword,
  useLogin,
} from "../../services/authService";
import { useNavigate } from "react-router-dom";
import PteSnackBar from "../../components/pteSnack";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import AppLogo from "../../components/appLogo";
import styles from "./auth.module.css";
import { isValidOTP, isValidPassword } from "../../utils";

const defaultTheme = createTheme();

export default function ChangePassword() {
  const [details, setDetails] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [otpError, setOtpError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigate = useNavigate();
  const {
    refetch: changePasswordService,
    data: changePasswordData,
    isLoading,
    isFetching,
  } = useChangePassword(details);
  const {
    refetch: forgotPasswordService,
    data: forgotPasswordData,
    isLoading: isResendLoading,
    isFetching: isResendFetching,
  } = useForgotPassword({ email });
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const enteredOtp = data.get("otp").trim();
    const enteredPassword = data.get("password")
    if (data.get("password") === data.get("confirmPassword")) {
      if (isValidOTP(enteredOtp)){
        if  (isValidPassword(enteredPassword)){
          setDetails({
            email: localStorage.getItem("email"),
            otp: enteredOtp,
            newPassword: enteredPassword,
          });
        }
        else {
      
          setPasswordError(true);
        }
      }
      else {
        setOtpError(true);
      }
      
    } else {
      setSnackMessage("Confirm password should be same as the new password");
      setSnackOpen(true);
    }
  };

  const handleClose = () => {
    setSnackOpen(false);
  };

  useEffect(() => {
    if (details) {
      changePasswordService();
    }
  }, [details]);

  useEffect(() => {
    if (changePasswordData && !isFetching) {
      if (
        changePasswordData.statusCode === "200" &&
        changePasswordData.status === "success"
      ) {
        navigate("/");
      } else {
        setSnackMessage(changePasswordData.message);
        setSnackOpen(true);
      }
    }
  }, [changePasswordData, isFetching]);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      navigate("/");
    }
  }, []);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className={styles.boxContainer}>
          <AppLogo />
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="otp"
              label="Enter OTP"
              name="otp"
              onChange={() => setOtpError(false)}
              error={otpError}
              helperText={otpError ? "Please enter a valid OTP" : ""}
              autoFocus
            />
            {showTimer ? (
              <CountdownCircleTimer
                isPlaying
                duration={120}
                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                isSmoothColorTransition
                colorsTime={[30, 20, 10, 5]}
                size={50}
                strokeWidth={6}
                onComplete={(totalElapsedTime) => {
                  setShowTimer(false);
                  return { shouldRepeat: startTimer, delay: 1.5 };
                }}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
            ) : (
              <LoadingButton
                onClick={() => {
                  forgotPasswordService();
                  setShowTimer(true);
                  setStartTimer(true);
                }}
                loading={isResendLoading || isResendFetching}
              >
                Resend OTP
              </LoadingButton>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              onChange={() => setPasswordError(false)}
              error={passwordError}
              helperText={passwordError ? "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character (e.g., !@#$%^&*)." : ""}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading || isFetching}
              sx={{ mt: 3, mb: 2 }}
            >
              Change Password
            </LoadingButton>
          </Box>
        </Box>
        <PteSnackBar
          message={snackMessage}
          snackOpen={snackOpen}
          handleClose={handleClose}
        />
      </Container>
    </ThemeProvider>
  );
}
