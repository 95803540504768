import React from "react";
import Logo from "../assets/images/logo.png";
import { Avatar } from "@mui/material";

export default function AppLogo() {
  return (
    <Avatar sx={{ m: 1, bgcolor: "#baf7d7", width: 50, height: 50 }}>
      <img src={Logo} style={{ height: "40px", width: "40px" }} />
    </Avatar>
  );
}
