import { useQuery } from "@tanstack/react-query";
import { dataFetcher } from "../utils";
import { baseURL } from "../constants";

export const useRegister = (details) => {
  const register = async () => {
    if (details) {
      const url = `${baseURL}v2/register`;
      const data = await dataFetcher(url, "post", details);
      return data;
    }
  };

  return useQuery({
    queryKey: ["register"],
    queryFn: register,
    enabled: false,
    retry: false,
  });
};

export const useSendOtp = (details) => {
  const sendOtp = async () => {
    if (details) {
      const url = `${baseURL}v1/user/sendOtp`;
      const data = await dataFetcher(url, "post", details);
      return data;
    }
  };

  return useQuery({
    queryKey: ["sendOtp"],
    queryFn: sendOtp,
    enabled: false,
    retry: false,
  });
};

export const useVerifyOtp = (details) => {
  const verifyOtp = async () => {
    if (details) {
      const url = `${baseURL}v1/user/verification`;
      const data = await dataFetcher(url, "post", details);
      return data;
    }
  };
  return useQuery({
    queryKey: ["verifyOtp"],
    queryFn: verifyOtp,
    enabled: false,
    retry: false,
  });
};

export const useLogin = (details) => {
  const login = async () => {
    if (details) {
      const url = `${baseURL}v1/login`;
      const data = await dataFetcher(url, "post", details);
      return data;
    }
  };
  return useQuery({
    queryKey: ["login"],
    queryFn: login,
    enabled: false,
    retry: false,
  });
};

export const useForgotPassword = (details) => {
  const forgotPassword = async () => {
    if (details) {
      const url = `${baseURL}v1/forgotPassword`;
      const data = await dataFetcher(url, "post", details);
      return data;
    }
  };

  return useQuery({
    queryKey: ["forgotPassword"],
    queryFn: forgotPassword,
    enabled: false,
    retry: false,
  });
};

export const useChangePassword = (details) => {
  const changePassword = async () => {
    if (details) {
      const url = `${baseURL}v1/changePassword`;
      const data = await dataFetcher(url, "post", details);
      return data;
    }
  };

  return useQuery({
    queryKey: ["changePassword"],
    queryFn: changePassword,
    enabled: false,
    retry: false,
  });
};
