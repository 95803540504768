import { useQuery } from "@tanstack/react-query";
import { dataFetcher } from "../utils";
import axios from "axios";
import { baseURL } from "../constants";

export const useConvert = (details) => {
  try {
    const convert = async () => {
      if (details.length) {
        for (let item of details) {
          // const url = `${baseURL}v3/file/upload/updatedVersion`;
          const url = `${baseURL}v4/file/upload/textractUpdated`;
          const data = axios.post(url, item, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
      }
    };
    return useQuery({
      queryKey: ["convert"],
      queryFn: convert,
      enabled: false,
      retry: false,
    });
  } catch (e) {
    return console.log(e);
  }
};

export const useGetStatus = (details) => {
  const getStatus = async () => {
    const url = `${baseURL}v1/fetchStatus`;
    const data = await dataFetcher(url, "post", details);
    return data;
  };
  return useQuery({
    queryKey: ["getStatus"],
    queryFn: getStatus,
    enabled:
      details &&
      details.fileIds &&
      details.fileIds.length &&
      details.fetchReady &&
      !details.stopFetch
        ? true
        : false,
    retry: false,
    refetchInterval: 3000,
  });
};
