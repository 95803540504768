import { LoadingButton } from "@mui/lab";
import { Button, MenuItem, Select, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useConvert, useGetStatus } from "../../services/convertService";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import uniqid from "uniqid";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveAppBar from "../../components/navbar";
import { DownloadForOffline, InfoOutlined } from "@mui/icons-material";
import JSZip from "jszip";
import PteSnackBar from "../../components/pteSnack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useIdleTimer } from "react-idle-timer";
import styles from "./dashboard.module.css";
import pdfLogo from "../../assets/images/pdf_icon.png";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  height: "200px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#d3d3d3",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  border: "1px solid black",
  margin: "50px 50px 0 50px",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function StyledDropzone(props) {
  const onIdle = () => {
    localStorage.clear("userId");
    localStorage.clear("isAdmin");
    window.location.reload();
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 20 * 60 * 1000,
  });
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "application/pdf": [],
      },
      onDrop: (acceptedFiles) => {
        if (!localStorage.getItem("userId")) {
          navigate("/login");
        } else {
          if (uploadedFiles.length + acceptedFiles.length <= 25) {
            setFileData([...fileData, ...acceptedFiles]);
          } else {
            setSnackMessage(
              "The maximum file limit is 25, and each file can be up to 10MB."
            );
            setSnackOpen(true);
          }
        }
      },
      onDropRejected: () => {
        setSnackMessage(
          "The maximum file limit is 25, and each file can be up to 10MB."
        );
        setSnackOpen(true);
      },
      maxFiles: 25,
      maxSize: 1e7,
    });

  const [fileIdArray, setFileIdArray] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fetchReady, setFetchReady] = useState(false);
  const [downloadArr, setDownloadArr] = useState([]);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [dropOpen, setDropOpen] = useState(true);
  const [modalOpen, setOpen] = useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);
  const [fileData, setFileData] = useState([]);
  const [errArray, setErrArray] = useState([]);
  const [stopFetch, setStopFetch] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState(null);
  const [delAllModal, setDelAllModal] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setSnackOpen(false);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setDelAllModal(false);
  };

  const removeAll = () => {
    setStopFetch(false);
    setFetchReady(false);
    setErrArray([]);
    setFileData([]);
  };

  const onDownloadAll = async () => {
    if (!localStorage.getItem("userId")) {
      navigate("/login");
    } else {
      const zip = new JSZip();
      for (let item of downloadArr) {
        const response = await fetch(item.url);
        const blob = await response.blob();
        zip.file(item.fName, blob);
      }
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = "files.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const deleteItem = (fileId) => {
    setDeleteFileId(fileId);
    setDeleteModalOpen(true);
  };

  const deleteItemFromModal = () => {
    if (delAllModal) {
      window.location.reload();
    } else {
      if (deleteFileId) {
        const tmpData = [...fileData];
        const postDeleteData = tmpData.filter((i) => i.fileId !== deleteFileId);
        setFileData(postDeleteData);
      }
    }
    setDeleteModalOpen(false);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const { refetch: convertService } = useConvert(uploadedFiles);

  const { data: statusData = {} } = useGetStatus({
    fileIds: fileIdArray,
    userId: localStorage.getItem("userId"),
    fetchReady,
    stopFetch,
  });

  const getStatus = (fileId) => {
    let finalStatus = "";
    const status =
      statusData &&
      statusData.data &&
      statusData.data.filter((item) => item.fileId === fileId);
    finalStatus = status && status[0] && status[0].status;
    let progressValue = 10;
    if (finalStatus === "COMPLETED") {
      return (
        <Link target="_blank" to={status[0].url}>
          <Button
            variant="outlined"
            startIcon={<DownloadForOffline />}
            color="success"
            size="large"
            style={{ color: "white", backgroundColor: "green" }}
          >
            Download
          </Button>
        </Link>
      );
    } else if (finalStatus === "FAILED") {
      progressValue = 0;
      return <h3 style={{ color: "red" }}>{status[0].errorDetails}</h3>;
    } else if (fetchReady) {
      let progressMessage = "Processing PDF";
      if (status && status.length) {
        let progressObj = JSON.parse(JSON.parse(status[0].progress));
        progressValue = progressObj ? progressObj.progressValue : 10;
        progressMessage = progressObj ? progressObj.message : "Processing PDF";
      }
      return (
        <div>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "170px" }}>
              <LinearProgress variant="determinate" value={progressValue} />
            </Box>
            <Box sx={{ minWidth: 35, marginLeft: "10px" }}>
              <Typography variant="body2" color="text.secondary">
                {progressValue + " %"}
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {progressMessage + "..."}
          </Typography>
        </div>
      );
    }
  };

  const acceptedFileItems = fileData.map((file) => {
    return (
      <div key={file.path} className={styles.singlePdfContainer}>
        <div className={styles.singlePdfFile}>
          <Tooltip title={file.path} placement="right-start">
            <div style={{ fontSize: "inherit", flex: 0.5 }}>
              <img
                src={pdfLogo}
                alt="pdf-img"
                style={{
                  width: "70px",
                  cursor: "pointer",
                  transition: "transform 0.3s ease-in-out",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "scale(1.3)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              />
            </div>
            <div style={{ flex: 0.3 }}>
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  width: "80px",
                  overflow: "hidden",
                  height: "20px",
                  margin: 0,
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  {file.path}
                </Typography>
              </Box>
            </div>
          </Tooltip>
        </div>
        {dropOpen && (
          <div style={{ marginLeft: "20px" }}>
            <LoadingButton
              variant="contained"
              style={{ margin: 0 }}
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                deleteItem(file.fileId);
              }}
            >
              Delete
            </LoadingButton>
          </div>
        )}

        <div style={{ marginLeft: "40px" }}>
          {fetchReady && getStatus(file.fileId)}
        </div>
      </div>
    );
  });

  const handleModalConfirm = () => {
    handleModalOpen();
  };

  const handleUploadNew = () => {
    if (!localStorage.getItem("userId")) {
      navigate("/login");
    } else {
      removeAll();
      handleModalClose();
      setDownloadArr([]);
      setDropOpen(true);
    }
  };

  const handleSubmit = () => {
    if (!localStorage.getItem("userId")) {
      navigate("/login");
    } else {
      setDropOpen(false);
      convertService();
      setFetchReady(true);
    }
  };

  useEffect(() => {
    if (fileData.length) {
      let fileIdArr = [];
      let payloadArr = [];
      let sizeValidationArr = [];
      for (let item of fileData) {
        sizeValidationArr.push(item.size < 10010000);
        setFetchReady(false);
        const uniqueId = uniqid();
        const payload = {
          userId: localStorage.getItem("userId"),
          fileId: uniqueId,
          file: item,
        };
        item.fileId = uniqueId;
        fileIdArr.push(uniqueId);
        payloadArr.push(payload);
      }
      if (payloadArr.length <= 25 && sizeValidationArr.every((i) => i)) {
        setUploadedFiles(payloadArr);
        setFileIdArray(fileIdArr);
      } else {
        setSnackMessage(
          "The maximum file limit is 25, and each file can be up to 10MB."
        );
        setSnackOpen(true);
      }
    } else {
      setFileIdArray([]);
    }
  }, [fileData]);

  useEffect(() => {
    if (statusData && statusData.data && statusData.data.length) {
      let tmpArr = [...downloadArr];
      let tmpErr = [...errArray];
      for (let item of statusData.data) {
        if (item.status === "COMPLETED") {
          if (tmpArr.findIndex((i) => i.fName === item.fileName) < 0) {
            let tmpObj = { url: item.url, fName: item.fileName };
            tmpArr.push(tmpObj);
          }
        } else if (item.status === "FAILED") {
          if (tmpErr.findIndex((i) => i === item.fileName) < 0) {
            tmpErr.push(item.fileName);
          }
        }
      }
      setDownloadArr(tmpArr);
    }
  }, [statusData && statusData.data]);

  useEffect(() => {
    if (
      downloadArr.length + errArray.length === fileData.length &&
      fetchReady
    ) {
      setStopFetch(true);
    }
  }, [downloadArr, errArray]);

  return (
    <div className="container">
      <ResponsiveAppBar pages={["PDF to Excel Converter"]} />
      {dropOpen && (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p style={{ color: "black", fontSize: "18px" }}>
            Drag 'n' drop some files here, or click to select files
          </p>
        </div>
      )}

      <div className={styles.dropContainerInfo}>
        {dropOpen ? (
          <div
            style={{
              display: "flex",
              width: "60%",
              alignItems: "center",
            }}
          >
            <InfoOutlined />
            <p style={{ margin: "0 0 0 10px" }}>
              The maximum number of files that can be converted at once is 25,
              and each file can be up to 10 MB in size.
            </p>
          </div>
        ) : (
          <div style={{ width: "70%" }}></div>
        )}

        {fetchReady &&
        downloadArr.length + errArray.length === fileData.length ? (
          <div className={styles.btnStyle}>
            <LoadingButton
              type="submit"
              variant="contained"
              style={{ margin: 0 }}
              sx={{ mt: 3, mb: 2 }}
              onClick={async () => {
                await onDownloadAll();
              }}
            >
              Download All
            </LoadingButton>
          </div>
        ) : null}

        {dropOpen ? (
          fileData.length ? (
            <div className={styles.btnStyle}>
              <LoadingButton
                variant="contained"
                style={{ margin: 0 }}
                sx={{ mt: 4, mb: 2 }}
                onClick={() => setDelAllModal(true)}
              >
                Delete All
              </LoadingButton>
            </div>
          ) : null
        ) : (
          <div className={styles.btnStyle}>
            <LoadingButton
              type="submit"
              variant="contained"
              style={{ margin: 0 }}
              sx={{ mt: 4, mb: 2 }}
              onClick={handleModalConfirm}
            >
              Upload New
            </LoadingButton>
          </div>
        )}

        {fileData.length && dropOpen ? (
          <div className={styles.btnStyle}>
            <LoadingButton
              type="submit"
              variant="contained"
              style={{ margin: 0 }}
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                handleSubmit();
              }}
              // loading={isLoading || isFetching}
            >
              Convert
            </LoadingButton>
          </div>
        ) : null}
      </div>
      <div>
        <div className={styles.mainContainer}>{acceptedFileItems}</div>
      </div>
      <PteSnackBar
        message={snackMessage}
        snackOpen={snackOpen}
        handleClose={handleClose}
      />
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Kind Attention
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Clicking the Proceed button will result in the loss of the current
            conversation data.
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "40%" }}></div>
            <Button
              type="submit"
              variant="contained"
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" onClick={handleUploadNew}>
              Proceed
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={deleteModalOpen || delAllModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "40%" }}></div>
            <Button
              type="submit"
              variant="contained"
              onClick={handleDeleteModalClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={deleteItemFromModal}
            >
              Proceed
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

<StyledDropzone />;
